import { Navigate, useNavigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import MainTemplate from "../Templates/Main/index";
import { lazy, useEffect } from "react";
import Loadable from "../components/Loadable";
// import TestDetails from "../views/Tests/TestDetails";

const ProtectedRoutes = ({ children }) => {
  const { state } = useAppContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (!state.user) {
      navigate("/auth/sign-in", { replace: true });
    }
  }, [state.user, navigate]);
  return children;
};
const AWSSetupLoader = Loadable(lazy(() => import("../views/AWSSetup")));
const ChangePasswordLoader = Loadable(
  lazy(() => import("../views/ChangePassword"))
);
const CheckoutLoader = Loadable(
  lazy(() => import("../views/Subscriptions/CheckoutSuccess"))
);
const DashboardLoader = Loadable(lazy(() => import("../views/Dashboard")));
const ProjectsLoader = Loadable(
  lazy(() => import("../views/Projects/ProjectsPage"))
);
const ReportsLoader = Loadable(lazy(() => import("../views/Reports")));
const SubscriptionsLoader = Loadable(
  lazy(() => import("../views/Subscriptions"))
);
const TestsLoader = Loadable(lazy(() => import("../views/Tests")));
const TestsReportLoader = Loadable(
  lazy(() => import("../views/Tests/Reports"))
);
const TestFormLoader = Loadable(
  lazy(() => import("../views/Tests/TestForm/index"))
);
const UsersLoader = Loadable(lazy(() => import("../views/Users")));
const SettingsLoader = Loadable(lazy(() => import("../views/Settings")));
const SummaryLoader = Loadable(lazy(() => import("../views/Summary")));
const MemberLoader = Loadable(lazy(() => import("../views/Member/index.jsx")));

const UserRoutes = () => {
  return {
    path: "/",
    element: (
      <ProtectedRoutes>
        <MainTemplate />
      </ProtectedRoutes>
    ),
    caseSensitive: false,
    children: [
      {
        path: "/",
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: "dashboard",
        element: <DashboardLoader />,
      },
      {
        path: "projects",
        children: [
          { path: "", element: <ProjectsLoader /> },
          { path: "/projects/:userId", element: <ProjectsLoader /> },
        ],
      },
      {
        path: "tests",
        children: [
          { path: "", element: <TestsLoader /> },
          { path: "/tests/test-form", element: <TestFormLoader /> },
          { path: "/tests/:id/details", element: <SummaryLoader /> },
          { path: "/tests/:id/html-report", element: <TestsReportLoader /> },
          {},
        ],
      },
      {
        path: "aws-setup",
        element: <AWSSetupLoader />,
      },
      {
        path: "subscriptions",
        element: <SubscriptionsLoader />,
      },
      {
        path: "checkout-success",
        element: <CheckoutLoader />,
      },
      {
        path: "change-password",
        element: <ChangePasswordLoader />,
      },
      {
        path: "reports",
        element: <ReportsLoader />,
      },
      {
        path: "users",
        element: <UsersLoader />,
      },
      {
        path: "settings",
        element: <SettingsLoader />,
      },
      {
        path: "member",
        element: <MemberLoader />,
      },
    ],
  };
};

export default UserRoutes;
