import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import NavigationScroll from "./components/NavigationScroll";
import AppRoutes from "./routes/AppRoutes";
import { AppProvider } from "./context/AppContext";
import LoadingModal from "./components/LoadingModal";
import AlertSection from "./components/AlertSection";
import { TabNavigationProvider } from "./context/TabContext";

function App() {
	return (
		<HelmetProvider>
			<BrowserRouter>
				<NavigationScroll>
					<TabNavigationProvider>
						<AppProvider>
							<AppRoutes />
							<LoadingModal />
							<AlertSection />
						</AppProvider>
					</TabNavigationProvider>
				</NavigationScroll>
			</BrowserRouter>
		</HelmetProvider>
	);
}

export default App;
